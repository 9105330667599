import React from "react"
import Layout from "../components/layout"
import {
  HeaderSecondary,
  ContactSection,
  DecorationSection,
  VoiceSection,
} from "../components/utility"

import { useStaticQuery, graphql } from "gatsby"

export default function Voice() {
  const data = useStaticQuery(graphql`
    query {
      brand2: file(relativePath: { eq: "product/voiceProduct.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const Product2 = [
    data.brand2.childImageSharp.fluid,
    `linear-gradient(0deg,
      rgba(0, 0, 0, 0.35),
      rgba(0, 0, 0, 0.35))`,
  ].reverse()

  return (
    <Layout>
      <HeaderSecondary
        fluid={Product2}
        title="NBN Business Line & Commander SIP"
        caption="Delivered over the NBN"
      />
      <VoiceSection />
      <ContactSection />
      <DecorationSection />
    </Layout>
  )
}
